<template>
    <div class="page page-introduction -frame">
        <h1>Poespas Media</h1>
        <h2>Empowering businesses with cutting-edge full-stack web development and DevOps solutions</h2>
        <router-link to="/about" class="btn-contact">Contact me</router-link>
    </div>
</template>

<script>
export default {
    name: "Introduction",
};
</script>

<style scoped>
.page-introduction {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    max-width: 600px;
}

.btn-contact {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--color-theme);
    color: var(--color-on-theme);
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    margin-bottom: 2rem;
}

.coc-number {
    font-size: 0.9rem;
    color: var(--color-text-muted);
}
</style>
