<template>
  <div class="business-card">
    <div class="header">
        <img src="../assets/logo.png" alt="Poespas Media Logo">
        <h1 id="name">Jonathan Visser</h1>
    </div>
    <div class="content">
        <div class="buttons">
            <button @click="makeCall">Call</button>
            <button @click="sendMail">Email</button>
        </div>
        
        <ul>
            <li @click="copyToClipboard(decode('RnJlZWxhbmNlIEZ1bGwgU3RhY2sgRGV2ZWxvcGVy'))">
                <div class="label">Job Title:</div>
                {{ decode('RnJlZWxhbmNlIEZ1bGwgU3RhY2sgRGV2ZWxvcGVy') }}
            </li>
            <li @click="copyToClipboard(decode('UG9lc3Bhcw=='))">
                <div class="label">Company:</div>
                {{ decode('UG9lc3Bhcw==') }}
            </li>
            <li @click="copyToClipboard(decode('KzMxNjI3ODY4OTk2'))">
                <div class="label">Phone:</div>
                {{ decode('KzMxNjI3ODY4OTk2') }}
            </li>
            <li @click="copyToClipboard(decode('Y29udGFjdEBwb2VzcGFzLm1l'))">
                <div class="label">Email:</div>
                {{ decode('Y29udGFjdEBwb2VzcGFzLm1l') }}
            </li>
            <li @click="openWebsite">
                <div class="label">Website:</div>
                {{ decode('aHR0cHM6Ly9wb2VzcGFzLm1l') }}
            </li>
        </ul>
    </div>
    
    <div class="vcf-button-holder">
        <button class="vcf-button" @click="downloadVCF">Download Contact</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessCard",
  methods: {
    decode(encoded) {
      return atob(encoded); // Decode base64 encoded string
    },
    makeCall() {
      window.location.href = "tel:" + this.decode('KzMxNjI3ODY4OTk2'); // Phone number should match
    },
    sendMail() {
      window.location.href = "mailto:" + this.decode('Y29udGFjdEBwb2VzcGFzLm1l'); // Email should match
    },
    openWebsite() {
      window.location.href = this.decode('aHR0cHM6Ly9wb2VzcGFzLm1l'); // Website should match
    },
    downloadVCF() {
      const vcfData = `BEGIN:VCARD
VERSION:3.0
FN:Jonathan Visser
TEL:${this.decode('KzMxNjI3ODY4OTk2')}
EMAIL:${this.decode('Y29udGFjdEBwb2VzcGFzLm1l')}
ORG:${this.decode('UG9lc3Bhcw==')}
TITLE:${this.decode('RnJlZWxhbmNlIEZ1bGwgU3RhY2sgRGV2ZWxvcGVy')}
END:VCARD`;

      const blob = new Blob([vcfData], { type: 'text/vcard' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Jonathan_Visser.vcf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value).then(() => {
        console.log(`${value} copied to clipboard!`);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  }
};
</script>

<style scoped>
.business-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100vh;
  color: #000;
}
.header {
    background-color: #2789e2;
    color: #fff;
    padding: 32px 0;
    gap: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header img {
    height: 100px;
}
.content {
    text-align: left;
    padding: 16px;
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.vcf-button-holder {
    position: fixed;
    bottom: 16px;
    right: 8px;
    lefT: 8px;
}
.business-card h1 {
  margin: 0;
  font-size: 24px;
}
.business-card ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}
.business-card li {
  margin-bottom: 10px;
}
.business-card .label {
    font-weight: bold;
    color: #333;
}
.business-card button {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  background-color: #0060c7;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.business-card button:hover {
  background-color: #0056b3;
}
.vcf-button {
  background-color: #28a745;
}
.vcf-button:hover {
  background-color: #218838;
}
</style>
