<template>
    <div class="page page-contact -frame">
        <h1>Contact me</h1>
        <ul class="contact-list">
            <li>
                <strong>Email:</strong> <a href="mailto:me@poespas.me">me@poespas.me</a>
            </li>
            <li>
                <strong>Phone:</strong> [coming soon]
            </li>
            <li>
                <strong>VAT number:</strong> NL003689241B04
            </li>
            <li>
                <strong>CoC number:</strong> 82484651
            </li>
        </ul>
        <div class="mt-2">
            <router-link to="/" custom class="more">
                Go back
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Contact",
};
</script>